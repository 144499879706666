import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import STORAGE from "../utils/local.storage";
import { AUTH_API, USER_API, LOOKUP_API } from "../apis";
import AccountMenu from "./AccountMenu";
import {
  Avatar,
  AppBar,
  Box,
  Button,
  // FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  // Select,
  Toolbar,
  Typography,
  Menu,
  Divider,
  Popper,
  Paper,
  MenuList,
  ClickAwayListener,
  Drawer,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import COLORS from "../utils/colors";
import { set } from "../actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightLogo from "../images/light_logo.png";
import DarkLogo from "../images/dark_logo.png";

const Header = ({
  mode,
  handleToggleChange,
  theme,
  showNavigation = true,
  selectedAvatar,
}) => {
  const { i18n } = useTranslation();
  // const activeLocale = i18n.resolvedLanguage;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conState = useSelector((state) => state.lookup);
  // const [lang, setLang] = useState("en");
  const [showButton, setShowButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [name, setName] = useState(""); // Define setName
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer
  const [categoriesOpen, setCategoriesOpen] = useState(false); // State for submenu
  const storage = STORAGE.getStorage();
  const { t } = useTranslation();

  // const handleChange = (event) => {
  //   setLang(event.target.value);
  // };

  const handleCategoriesClick = () => {
    setCategoriesOpen((prevOpen) => !prevOpen);
  };

  const getCategory = async () => {
    try {
      const res = await LOOKUP_API.getLookup();
      if (res) {
        setCategories(res?.categories);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const loginUser = async () => {
    try {
      const auth_user = await AUTH_API.loginOpenUser();
      dispatch(
        set({ authorities: auth_user?.authorities, token: auth_user?.token })
      );
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleAccountMenuOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleMenuItemClick = (item) => handleClose();

  const NestedMenuItem = ({ item, handleMenuItemClick, parentMenuOpen }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
      if (item?.subCategories?.length > 0) {
        setAnchorEl(event.currentTarget);
      }
    };

    const handleMouseLeave = () => setAnchorEl(null);
    const open = Boolean(anchorEl) && parentMenuOpen;

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {/* <MenuItem
          onClick={() => {
            handleMenuItemClick(item);
            navigate("/search", {
              state: {
                category_id: item?._id,
              },
            });
          }}
        >
          {item?.name}
        </MenuItem> */}
        {item?.subCategories?.length > 0 && (
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="right-start"
            style={{ zIndex: 1300 }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <MenuList>
                  {item?.subCategories?.map((subItem) => (
                    <NestedMenuItem
                      key={subItem.id}
                      item={subItem}
                      handleMenuItemClick={handleMenuItemClick}
                      parentMenuOpen={open}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        )}
      </div>
    );
  };

  const getInfo = async () => {
    try {
      const res = await USER_API.getProfile();
      if (res && res?.user) {
        setName(res?.user?.name);
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  useEffect(() => {
    const data = STORAGE.getStorage();
    if (data) {
      if (!data?.authorities?.includes("OPEN_USER")) setIsLogin(true);
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: theme?.palette?.background?.main,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: theme?.palette?.background?.main,
          display: "flex",
          height: "66px",
          width: "100%",
          maxWidth: "1200px",
          alignItems: "center",
          justifyContent: { xs: "space-between", sx: "default" },
          paddingX: 2,
          margin: "0 auto",
          "@media (min-width: 600px)": {
            paddingLeft: "7px !important",
            paddingRight: "7px !important",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              display: {
                xs: "block",
                md: "none",
              },
            }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon sx={{ color: theme.palette.background.media }} />
          </IconButton> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              width: { xs: "90px", sm: "120px" },
            }}
            onClick={() => navigate("/")}
          >
            {theme?.palette?.background?.main === "#fff !important" ? (
              <img
                src={conState?.branding?.logoURL || LightLogo}
                alt=""
                style={{ maxWidth: "120px", minWidth: "90px", height: "auto" }}
              />
            ) : null}
            {theme?.palette?.background?.main === "#1E1E1E !important" ? (
              <img
                src={conState?.branding?.logoURL || DarkLogo}
                alt=""
                style={{ maxWidth: "120px", minWidth: "90px", height: "auto" }}
              />
            ) : null}
          </Box>
        </Box>
      </Toolbar>
      <Divider sx={{ height: "4px", color: COLORS.divider }} />
    </AppBar>
  );
};

export default Header;
