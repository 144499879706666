import React, { useState } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Box,
  CircularProgress,
} from "@mui/material";
import bigComing from "../assets/bigComing.png";
import "../App.css";
import BackgroundImage from "../backgroundImage.svg";
import { useNavigate } from "react-router-dom";
import CustomInputLabel from "../components/CustomLabelInput";
import { useSnackbar } from "../context/SnackbarContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LOOKUP_API } from "../apis";

const Waitlist = ({ theme, mode, handleToggleChange }) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgNameErr, setOrgNameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [query, setQuery] = useState("");
  const [queryErr, setQueryErr] = useState("");
  const [isLoading, setLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const validateEmail = (str = "") => {
    return emailRegex.test(str);
  };

  const handleSubmit = async () => {

    // Reset error messages
    setNameErr("");
    setEmailErr("");
    setOrgNameErr("");
    setQueryErr("");

    // Basic validation
    let isValid = true;

    if (name.trim() === "") {
      setNameErr("Name is required.");
      isValid = false;
    }

    if (email.trim() === "") {
      setEmailErr("Email is required.");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailErr("Please enter a valid email.");
      isValid = false;
    }

    if (query.trim() === "") {
      setQueryErr("Please provide some details about your inquiry.");
      isValid = false;
    }

    if (!isValid) return;

    setLoading(true);
    const body = {
      name,
      email,
      orgName,
      query
    }

    const res = await LOOKUP_API.contactUs(body);
    console.log('RES ', res)
    if(res) {
      showSnackbar({
        message: "Your inquiry has been submitted successfully!",
        severity: "success",
      });
      setName("");
      setEmail("");
      setOrgName("");
      setQuery("");
    }
    setLoading(false);

    // // Simulate form submission
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   showSnackbar("Your inquiry has been submitted successfully!", "success");
    //   navigate("/thank-you"); // Redirect to a thank you page or any other page
    // }, 2000);
  };

  return (
    <React.Fragment>
      <Header
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      />
      <Grid
        mt={5}
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          background: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundColor: theme.palette.background.default,
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            border: "1px solid #E2F0FA",
            display: "flex",
            borderRadius: "6px",
            justifyContent: "space-between",
            backgroundColor: theme.palette.background.main,
          }}
          //   ,
        >
          <Box sx={{}}>
            <form>
              <Grid container direction={"column"} sx={{ padding: 5 }}>
                <Box
                  mb={2}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: theme.palette.background.mainH1,
                      fontSize: "20px",
                    }}
                    variant="subtitle1"
                  >
                    Join the waitlist.
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.text.body }}
                    variant="subtitle1"
                  >
                    The future of learning is here!
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.text.body }}
                    variant="subtitle1"
                  >
                    Join an exclusive new platform where 
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.text.body }}
                    variant="subtitle1"
                  >
                    teaching and learning go beyond the ordinary, 
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.text.body }}
                    variant="subtitle1"
                  >
                    fostering communities that connect, collaborate,
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: theme.palette.text.body }}
                    variant="subtitle1"
                  >
                    and grow together. Be part of something extraordinary!
                  </Typography>
                </Box>

                <Grid item xs={8} md={6} mb={1}>
                  <CustomInputLabel title={"Name"} isRequired={true} />
                  <TextField
                    sx={{
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      width: "440px",
                      height: "40px",
                      background: theme.palette.background.main,
                      border: "1px solid #E2F0FA",
                      "& .MuiOutlinedInput-root": {
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        background: theme.palette.background.main,
                        "& input": {
                          padding: "0 10px",
                        },
                        "& input::placeholder": {
                          fontSize: "14px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    id="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr("");
                    }}
                    helperText={nameErr}
                    error={nameErr.length > 0 ? true : false}
                  />
                </Grid>

                <Grid item xs={8} md={6} mb={1}>
                  <CustomInputLabel title={"Email"} isRequired={true} />
                  <TextField
                    sx={{
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      width: "440px",
                      height: "40px",
                      background: theme.palette.background.main,
                      border: "1px solid #E2F0FA",
                      "& .MuiOutlinedInput-root": {
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        background: theme.palette.background.main,
                        "& input": {
                          padding: "0 10px",
                        },
                        "& input::placeholder": {
                          fontSize: "14px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErr("");
                    }}
                    helperText={emailErr}
                    error={emailErr.length > 0 ? true : false}
                  />
                </Grid>

                {/* <Grid item xs={8} md={6} mb={2}>
                  <CustomInputLabel
                    title={"Organization Name"}
                    isRequired={false}
                  />
                  <TextField
                    sx={{
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      width: "440px",
                      height: "40px",
                      background: theme.palette.background.main,
                      border: "1px solid #E2F0FA",
                      "& .MuiOutlinedInput-root": {
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        background: theme.palette.background.main,
                        "& input": {
                          padding: "0 10px",
                        },
                        "& input::placeholder": {
                          fontSize: "14px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    id="orgName"
                    value={orgName}
                    placeholder="Enter organization name"
                    onChange={(e) => {
                      setOrgName(e.target.value);
                      setOrgNameErr("");
                    }}
                    helperText={orgNameErr}
                    error={orgNameErr.length > 0 ? true : false}
                  />
                </Grid> */}

                <Grid item xs={8} md={6}>
                  <CustomInputLabel
                    title={"What can we help you with?"}
                    isRequired={false}
                  />
                  <TextField
                    sx={{
                      borderRadius: "4px",
                      width: "440px",
                      maxHeight: "80px",
                      overflow: "auto", // Enable scrolling
                      background: theme.palette.background.main,
                      border: "1px solid #E2F0FA",
                      "& .MuiOutlinedInput-root": {
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        background: theme.palette.background.main,

                        "& textarea": {
                          padding: "10px",
                          maxHeight: "200px", // Set the maximum height for the textarea
                          overflowY: "auto", // Enable vertical scrolling for the textarea
                        },
                        "& textarea::placeholder": {
                          fontSize: "14px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                    id="query"
                    placeholder="Enter your query"
                    multiline
                    minRows={3}
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                      setQueryErr("");
                    }}
                    helperText={queryErr}
                    error={queryErr.length > 0 ? true : false}
                  />
                </Grid>

                <Grid item xs={8} md={6}>
                  <Button
                    sx={{
                      width: "140px",
                      height: "38px",
                      mt: 2,
                      textTransform: "none",
                      backgroundColor: theme.palette.background.primary,
                      color: "white",
                      "&:hover": {
                        backgroundColor: theme.palette.background.primary,
                        color: "white",
                      },
                    }}
                    fontWeight="regular"
                    disableElevation
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={30}
                        sx={{
                          color: "white",
                        }}
                      />
                    ) : (
                      <Typography fontSize={"15px"}>Submit</Typography>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img className="img_contactUs" src={bigComing} alt="" />
          </Box>
        </Box>
      </Grid>
      {/* <Footer
        theme={theme}
        mode={mode}
        handleToggleChange={handleToggleChange}
      /> */}
    </React.Fragment>
  );
};

export default Waitlist;
